import React from "react";

const InvoiceTable = ({ data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border border-gray-300 rounded-lg shadow-md">
        <thead>
          <tr className="bg-gray-200 text-gray-700 uppercase text-sm">
            <th className="px-4 py-3 text-left">Description</th>
            <th className="px-4 py-3 text-center">QTY</th>
            <th className="px-4 py-3 text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="px-4 py-4">
                {data?.app_name} Videos ({item?.name})
              </td>
              <td className="px-4 py-4 text-center">{item?.quantity}</td>
              <td className="px-4 py-4 text-center">₹{item?.total}</td>
            </tr>
          ))}

          {/* Grand Total Row */}
          <tr className="bg-gray-200 font-bold mt-4">
            <td className="px-4 py-2 text-right" colSpan={2}>
              Grand Total:
            </td>
            <td className="px-4 py-2 text-center">₹{data?.total_price}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
