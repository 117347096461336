import React from "react";

const GlobalNavProgress = () => {
  return (
    <div className="w-full mx-auto">
      <div className="relative w-full bg-gray-3p00 h-[5px] overflow-hidden">
        <div className="absolute left-0 top-0 h-full bg-[#AD49E1] w-full animate-marquee"></div>
      </div>

      {/* Tailwind animation */}
      <style>
        {`
          @keyframes marquee {
            0% { left: -33%; }
            100% { left: 100%; }
          }
          .animate-marquee {
            animation: marquee 1s linear infinite;
          }
        `}
      </style>
    </div>
  );
};

export default GlobalNavProgress;
