import React from "react";
import { Container, Typography } from "@mui/material";
import Loader from "../Loader";

const OverlayLoader = ({ className }) => {
  return (
    <Container>
      <div
        className={`fixed top-[50px] right-0 bottom-0 w-full z-[9999999] overflow-hidden !bg-white opacity-100 flex flex-col items-center justify-center md:!w-full lg:!w-[86.1%] lg:!top-[55px] ${
          className || ""
        }`}
      >
        <Loader />
        <Typography
          component="body-regular"
          variant="light"
          className="text-center text-offWhite !mt-6"
        >
          This may take a few seconds, please don&apos;t close this page.
        </Typography>
      </div>
    </Container>
  );
};

export default OverlayLoader;
