import React, { useState } from "react";
import { apiGateway } from "../../utils/config";
import { useSelector } from "react-redux";
import { selectConfigFilters } from "../../redux/config/config.selector";
import {
  creatorTypeList,
  incentiveMissingFieldsState,
  payoutTypeList,
  tdsList,
} from "../../utils/constants/Creators";
import CustomModal from "../Common/CustomModal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomInput from "../Common/CustomInput";
import InputTitleWithRequired from "../Common/InputTitleWithRequired";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useZustandStore from "../../zustandStore/useZustandStore";

const AddOrEditIncentiveModal = ({
  openModal,
  incentiveDetails,
  setIncentiveDetails,
  handleClose,
  handleGetAllCreators,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [missingFields, setMissingField] = useState(
    incentiveMissingFieldsState
  );
  const axiosPrivate = useAxiosPrivate();
  const configFilters = useSelector(selectConfigFilters);
  const contentManagers = configFilters.contentManager;
  const setToastMessage = useZustandStore((val) => val.setToastMessage);

  // Functions checks the missing fields
  const handleMissingFields = () => {
    const updatedMissingFields = {
      ...incentiveMissingFieldsState,
    };
    Object.keys(incentiveDetails).forEach((field) => {
      // skipping the amount field validation when payoutType is variable
      if (field === "amount" && incentiveDetails.payoutType === "variable") {
        updatedMissingFields[field] = false;
        return;
      }
      if (!incentiveDetails[field]) {
        updatedMissingFields[field] = true;
      } else {
        updatedMissingFields[field] = false;
      }
    });
    setMissingField(updatedMissingFields);
    const hasMissingFields = Object.values(updatedMissingFields).includes(true);
    return hasMissingFields;
  };

  // Functions to update the Incentive
  const handleUpdateIncentiveAPICall = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/creator/${incentiveDetails?.creatorId}/payout/`;
      const payload = new FormData();
      payload.append("payout_type", incentiveDetails?.payoutType);
      payload.append("fixed_amount", incentiveDetails?.amount);
      payload.append("creator_type", incentiveDetails?.creatorType);
      payload.append("content_manager_id", incentiveDetails?.contentManagerId);
      payload.append("tds_rate", incentiveDetails?.tdsRate);
      payload.append("start_date", incentiveDetails?.agreementDate);
      const response = await axiosPrivate.post(url, payload);
      const { data } = response;
      if (data) {
        setIsLoading(false);
        handleGetAllCreators();
        handleClose();
        setToastMessage({
          type: "success",
          message: "Incentive Updated Successfully",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function invoked when clicked on save
  const handleSaveIncentive = async () => {
    if (handleMissingFields()) {
      setToastMessage({
        type: "error",
        message: "Some fields are required to proceed further",
      });
    } else {
      handleUpdateIncentiveAPICall();
    }
  };

  return (
    <CustomModal
      title={`Incentive Structure ${incentiveDetails?.creatorName}`}
      className="lg:w-[35%]"
      handleConfirmationBtnText="Save"
      show={openModal}
      onHide={handleClose}
      isLoading={isLoading}
      handleConfirmation={handleSaveIncentive}
    >
      <div>
        <div className="flex gap-x-5">
          <FormControl className="w-[50%]">
            <InputTitleWithRequired title="Payout Type" isRequired />
            <Select
              displayEmpty // Allows empty values to show placeholder
              className="!text-[14px] mt-1 !py-0"
              value={incentiveDetails.payoutType}
              error={missingFields?.payoutType}
              onChange={(e) => {
                const { value } = e.target;
                setMissingField({
                  ...missingFields,
                  payoutType: false,
                  amount: false,
                });
                setIncentiveDetails({
                  ...incentiveDetails,
                  payoutType: value,
                  amount: "",
                });
              }}
            >
              {payoutTypeList.map((item) => {
                return (
                  <MenuItem
                    key={item?.title}
                    value={item?.value}
                    className="!text-[14px]"
                    disabled={item?.disable}
                  >
                    {item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl className="w-[50%]">
            <InputTitleWithRequired title="Amount" isRequired />
            <CustomInput
              id="amount-input"
              type="number"
              className={` !text-[14px] mt-1 placeholder:text-black px-2 py-2 h-full border !border-gray-300 rounded-lg ${
                incentiveDetails?.payoutType === "variable"
                  ? " bg-gray-200 cursor-not-allowed"
                  : ""
              }`}
              placeholder="Enter amount"
              value={incentiveDetails.amount}
              error={missingFields?.amount}
              disabled={incentiveDetails?.payoutType === "variable"}
              onChange={(e) => {
                const { value } = e.target;
                setMissingField({ ...missingFields, amount: false });
                setIncentiveDetails({ ...incentiveDetails, amount: value });
              }}
            />
          </FormControl>
        </div>

        <div className="flex gap-x-5 mt-4">
          <FormControl className="w-[50%]">
            <InputTitleWithRequired title="Creator Type" isRequired />
            <Select
              displayEmpty
              className="!text-black !text-[14px] mt-1"
              value={incentiveDetails.creatorType}
              error={missingFields?.creatorType}
              onChange={(e) => {
                const { value } = e.target;
                setMissingField({ ...missingFields, creatorType: false });
                setIncentiveDetails({
                  ...incentiveDetails,
                  creatorType: value,
                });
              }}
            >
              {creatorTypeList.map((item) => {
                return (
                  <MenuItem
                    key={item?.title}
                    value={item?.value}
                    className="!text-[14px]"
                    disabled={item?.disable}
                  >
                    {item?.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl className="w-[50%]">
            <InputTitleWithRequired title="Content Manager" isRequired />
            <Select
              displayEmpty
              className="!text-black !text-[14px] mt-1"
              value={incentiveDetails?.contentManagerId}
              error={missingFields?.contentManagerId}
              onChange={(e) => {
                const { value } = e.target;
                setMissingField({ ...missingFields, contentManagerId: false });
                setIncentiveDetails({
                  ...incentiveDetails,
                  contentManagerId: value,
                });
              }}
            >
              <MenuItem className="!text-[14px]" disabled>
                Select Content Manager
              </MenuItem>
              {contentManagers?.map((item) => {
                return (
                  <MenuItem
                    key={item?.name}
                    value={item?.id}
                    className="!text-[14px]"
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className="mt-4 flex gap-x-5">
          <FormControl className="w-full">
            <InputTitleWithRequired title="TDS Rate" isRequired />
            <Select
              displayEmpty
              className="!text-black !text-[14px] mt-1"
              value={incentiveDetails.tdsRate}
              error={missingFields?.tdsRate}
              onChange={(e) => {
                const { value } = e.target;
                setMissingField({ ...missingFields, tdsRate: false });
                setIncentiveDetails({ ...incentiveDetails, tdsRate: value });
              }}
            >
              {tdsList.map((item) => {
                return (
                  <MenuItem
                    key={item?.title}
                    value={item?.value}
                    className="!text-[14px]"
                    disabled={item?.disabled}
                  >
                    {item?.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl className="w-full">
            <InputTitleWithRequired
              title="Agreement Generation Date"
              isRequired
            />
            <CustomInput
              type="date"
              value={incentiveDetails?.agreementDate}
              error={missingFields.agreementDate}
              onChange={(e) => {
                const { value } = e.target;
                setIncentiveDetails({
                  ...incentiveDetails,
                  agreementDate: value,
                });
                setMissingField({ ...missingFields, agreementDate: false });
              }}
              className="!text-[14px] mt-1 placeholder:text-black px-2 py-2 h-full border !border-gray-300 rounded-lg"
            />
          </FormControl>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddOrEditIncentiveModal;
