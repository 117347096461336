import React, { useRef, useMemo, useEffect } from "react";
import { apiGateway } from "../../../utils/config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Editor.css";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useZustandStore from "../../../zustandStore/useZustandStore";

const QuillEditor = ({
  editorHtml,
  className,
  blogSlug,
  isLoading,
  handleChange,
  setIsLoading,
}) => {
  const quillRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((val) => val.setToastMessage);

  const handleUploadToS3 = async ({ file, key, altText }) => {
    try {
      const formData = new FormData();
      formData.append("assets", file);
      formData.append("url", key);
      const response = await axiosPrivate.post("/upload-assets", formData);
      if (response?.data) {
        setIsLoading({ ...isLoading, uploadImage: false });
        // Appending image to <img /> tag with alt text
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        const imgTag = `<img src="${response?.data?.data?.url}" alt="${
          altText || ""
        }" />`;
        editor.clipboard.dangerouslyPasteHTML(range.index, imgTag);
      }
    } catch (error) {
      setIsLoading({ ...isLoading, uploadImage: false });
      setToastMessage({
        type: "error",
        message: error?.message,
      });
    }
  };

  // Function to upload image to S3
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".png, .jpeg, .jpg");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const extension = file.type?.split("/")[1];
      if (file) {
        try {
          // Prompt for alt text after image selection
          const altText = prompt("Enter alt text for the image:");
          setIsLoading({ ...isLoading, uploadImage: true });
          const url = `${apiGateway}/api/v1/blog/${blogSlug}/blog-signed-url/?media_extension=${extension}`; // getting the key to upload into S3
          const response = await axiosPrivate.get(url);
          const result = response.data;
          const key = response?.data?.upload_presigned_url?.fields?.key;
          if (result) {
            handleUploadToS3({ file, key, altText });
          }
        } catch (error) {
          setIsLoading({ ...isLoading, uploadImage: false });
          setToastMessage({
            type: "error",
            message: error?.response?.data?.error_message || error?.message,
          });
        }
      }
    };
  };

  const videoHandler = () => {
    const url = prompt("Paste a embed url of Youtube or Vimeo");
    if (url) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      editor.insertEmbed(range.index, "video", url);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          [{ align: "center" }],
          ["bold", "italic", "blockquote", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image", "video", "code"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
          video: videoHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = [
    "header",
    "align",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "alt",
    "video",
    "code",
  ];

  // Function to scroll to the end of the editor
  const scrollToBottom = () => {
    const editorContainer = quillRef.current?.getEditor()?.container;
    if (editorContainer) {
      editorContainer.scrollTop = editorContainer.scrollHeight;
    }
  };

  // Calling scrollToBottom whenever editorHtml changes
  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100); // Timeout to ensure content has been rendered
    return () => clearTimeout(timer);
  }, [editorHtml]);

  return (
    <>
      <ReactQuill
        placeholder="Start Writing....."
        onChange={handleChange}
        theme="snow"
        modules={modules}
        formats={formats}
        value={editorHtml}
        ref={quillRef}
        className={className}
        id="QuillEditor"
      />
    </>
  );
};

export default QuillEditor;
