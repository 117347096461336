import React from "react";
import GenerateInvoiceComponent from "../../components/GenerateInvoice";

const GenerateInvoice = () => {
  return (
    <div className="mt-3">
      <GenerateInvoiceComponent />
    </div>
  );
};

export default GenerateInvoice;
