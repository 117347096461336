import React from "react";
import ArivuLogo from "../../assests/images/arivu-grey-logo.webp";
import SeekhoLogo from "../../assests/images/seekho-grey-logo.webp";

const Header = ({ data }) => {
  const appName = data?.app_name;

  const handleAppLogo = ({ appName }) => {
    switch (appName) {
      case "Arivu":
        return (
          <img
            src={ArivuLogo}
            alt="arivu-logo"
            className="w-[165px] h-[55px]  "
          />
        );
      case "Seekho":
        return (
          <img
            src={SeekhoLogo}
            alt="seekho-logo"
            className="w-[170px] h-[65px]  "
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="flex justify-between">
      <div className="flex gap-x-3">{handleAppLogo({ appName })}</div>
      <div>
        <h1 className="text-[24px] font-bold">INVOICE</h1>
        <h6 className="mt-2 text-[15px]">Invoice Date: {data?.date}</h6>
        <h6 className="mt-2 text-[15px]">
          INVOICE NO: #{data?.invoice_number}
        </h6>
      </div>
    </div>
  );
};

export default Header;
