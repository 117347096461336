import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getDuration, handleFormatDate } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from "react-router-dom";
import { errorState } from "../../utils/constants";
import { handleFormatPayout } from "../../utils/constants/Dashboard";
import { useDispatch } from "react-redux";
import { apiGateway } from "../../utils/config";
import {
  changeSeriesStatusAsync,
  fetchRejectionReason,
} from "../../redux/series/series.action";
import {
  handleTableHeading,
  seriesStatus,
} from "../../utils/constants/VideoListing";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertComponent from "../Alert-Messages/alert-component.component";
import RejectionReasonModal from "./RejectionReasonModal";
import RejectSeriesModal from "./RejectSeriesModal";
import SeriesIdCopy from "../Common/SeriesIdCopy";
import VideoPlayOnSeriesClick from "../Common/VideoPlayOnSeriesClick";
import CustomAnchor from "../Common/CustomAnchor";
import useUserRoles from "../../hooks/useUserRoles";
import ApproveOrRejectVideoModal from "../Common/ApproveOrRejectVideoModal";
import ModeratorStatusChip from "../Common/ModeratorStatusChip";
import VideoStatusChip from "../Common/VideoStatusChip";
import useZustandStore from "../../zustandStore/useZustandStore";

const SeriesListTable = ({
  allSeries,
  setOpenScheduleModal,
  setScheduledData,
  handleRemoveScheduled,
  handleGetSeries,
}) => {
  const [openRejectionReason, setOpenRejectReason] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorState);
  const [openRejectSeriesModal, setOpenRejectSeriesModal] = useState({
    slug: "",
    open: false,
    reason: "",
  });
  const [
    approveOrRejectVideoConfirmation,
    setApproveOrRejectVideoConfirmation,
  ] = useState({ approve: false, reject: false, seriesSlug: "" });
  const {
    isCreator,
    internalTeamAccessWithViewer,
    internalTeamAccess,
    isModerator,
    hasAccessToMakeItLive,
  } = useUserRoles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((state) => state.setToastMessage);
  const searchParams = new URLSearchParams(location?.search);
  const isExpiredTab = searchParams.get("state")?.includes("expired");
  const isScheduledTab = searchParams?.get("state")?.includes("scheduled");
  const isAnyTab = searchParams?.get("state");
  const isAllTab = isAnyTab?.includes("all");

  const hasAccessToActions = internalTeamAccess || isModerator;
  const hasAccessToCategoryAndCreatorCol =
    internalTeamAccessWithViewer || isModerator;

  const handleRejectSeries = () => {
    if (openRejectSeriesModal?.reason?.length > 1) {
      dispatch(
        changeSeriesStatusAsync(
          axiosPrivate,
          openRejectSeriesModal?.slug,
          "changes_required",
          openRejectSeriesModal?.reason,
          () => {},
          false,
          setErrorMsg,
          handleGetSeries
        )
      );
      setOpenRejectSeriesModal({ open: false, reason: "", slug: "" });
    } else {
      setErrorMsg({
        type: "failed",
        error: "Please fill the reason !",
      });
    }
  };

  const handleRouteToVideo = ({ videoSlug, status }) => {
    if (status === "live") {
      history.push(`/videos-detail/${videoSlug}`);
    } else {
      history.push(`/videos/${videoSlug}`);
    }
  };

  // Function to move video to expired
  const handleMoveVideoToExpired = async ({ slug }) => {
    try {
      const url = `${apiGateway}/api/v1/cms/${slug}/expire/`;
      const response = await axiosPrivate.post(url);
      if (response?.data) {
        handleGetSeries();
        setToastMessage({
          type: "success",
          message: "Video moved to expired",
        });
      }
    } catch (error) {
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 !bg-gray-100 border-b">
          <tr>
            {handleTableHeading({
              isScheduledTab,
              isExpiredTab,
              hasAccessToActions,
              isCreator,
              categoryAndCreatorTab: hasAccessToCategoryAndCreatorCol,
            }).map((item) => {
              return (
                item?.display && (
                  <th
                    scope="col"
                    className={`px-3 py-3 whitespace-nowrap text-gray-700 !text-[11px] !bg-gray-100 z-[99] !font-medium ${
                      item?.title === "Title" ||
                      item?.title === "Uploaded On" ||
                      item?.title === "Published On" ||
                      item?.title === "Scheduled On" ||
                      item?.title === "Thumbnail"
                        ? "text-left"
                        : "text-center"
                    }`}
                    key={item.id}
                  >
                    {item?.title}
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {allSeries?.map((item) => {
            const isUnderReview = item?.status === seriesStatus.UNDER_REVIEW;
            const isLive = item?.status === seriesStatus.LIVE;
            const isDeleted = item?.status === seriesStatus.DELETED;
            const isScheduled = item?.status === seriesStatus.SCHEDULED;
            const isChangesRequired =
              item?.status === seriesStatus.CHANGES_REQUIRED;
            const stats = item?.stats;
            return (
              <tr
                className="last:!border-none border-b text-black !font-normal text-[12px]"
                key={item?.id}
              >
                <td className={`px-3 py-3 whitespace-nowrap`}>
                  <VideoPlayOnSeriesClick
                    thumbnail={item?.original_image}
                    title={item?.display_title}
                    slug={item?.slug}
                    n_units={item?.n_units}
                    thumbnailClassName="rounded-md"
                    thumbnailWrapperClassName="relative !min-w-[84px] !max-w-[84px] h-[116px] cursor-pointer"
                    playIconClassName="text-4xl"
                    setMessage={setErrorMsg}
                  />
                </td>

                <td
                  className={` px-3 py-2 text-[13px] ${
                    isCreator
                      ? "!min-w-[200px] lg:!w-[150px]"
                      : "!max-w-[240px]"
                  }`}
                >
                  <span className="cursor-pointer">
                    <CustomAnchor
                      displayText={item?.display_title}
                      href={`${
                        item?.status === "live"
                          ? `#/videos-detail/${item?.slug}`
                          : `#/videos/${item?.slug}`
                      } `}
                    />
                  </span>

                  <div className="flex gap-x-1 items-center whitespace-nowrap">
                    <SeriesIdCopy
                      seriesId={item?.id}
                      setMessage={setErrorMsg}
                    />
                    {!isCreator && (
                      <div className="text-gray-500 mt-1">
                        {" "}
                        • {getDuration(item?.duration_s)}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className={` whitespace-nowrap px-3 py-2 text-[13px] text-gray-500 lg:!whitespace-normal ${
                    isCreator ? "" : "lg:!w-[130px]"
                  }`}
                >
                  {handleFormatDate({
                    dateInString: isScheduledTab
                      ? item?.scheduled_info?.schedule_on
                      : item?.created_on,
                  })}
                </td>

                {!isScheduledTab && (
                  <td
                    className={` whitespace-nowrap px-3 py-2 text-[13px] text-gray-500 lg:whitespace-normal ${
                      isCreator ? "" : "lg:!w-[130px]"
                    }`}
                  >
                    {handleFormatDate({
                      dateInString: isScheduled
                        ? item?.scheduled_info?.schedule_on
                        : item?.approved_on,
                    })}
                  </td>
                )}

                <td className="px-3 py-2 text-[13px] whitespace-nowrap text-gray-500 text-center">
                  <div>
                    {stats?.completion_rate ? (
                      <span
                        className={`font-semibold ${
                          stats?.completion_rate < stats?.target_completion_rate
                            ? "text-red-500"
                            : "text-green-600"
                        }`}
                      >
                        {stats?.completion_rate}%
                      </span>
                    ) : (
                      "-"
                    )}{" "}
                    <span className="text-gray-500">
                      {stats?.target_completion_rate
                        ? `(${stats?.target_completion_rate}%)`
                        : "-"}
                    </span>
                  </div>
                </td>

                {isCreator && (
                  <td className="px-3 py-2 text-[12px] whitespace-nowrap text-gray-500 text-center">
                    <div className="">
                      {stats?.payout ? (
                        <p className="text-[13px] text-gray-500">
                          {`₹${handleFormatPayout({
                            totalPayout: stats?.payout || 0,
                          })}`}
                        </p>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                )}

                {isCreator && (
                  <td className="px-3 py-2 text-[13px] whitespace-nowrap text-gray-500 text-center">
                    {getDuration(item?.duration_s)}
                  </td>
                )}

                {hasAccessToCategoryAndCreatorCol && (
                  <td className="px-3 py-2 text-[14px] capitalize whitespace-nowrap text-gray-500 text-center">
                    {item?.category?.title}
                  </td>
                )}

                {hasAccessToCategoryAndCreatorCol && (
                  <td className="px-3 py-2 text-[14px] capitalize whitespace-nowrap text-gray-500 text-center lg:!min-w-[150px] lg:whitespace-normal">
                    <CustomAnchor
                      displayText={item?.creator?.name}
                      href={`#/creators/${item?.creator?.id}`}
                    />
                  </td>
                )}

                <td
                  className={` px-3 py-2 text-[14px] capitalize text-center `}
                >
                  <div className="flex flex-col gap-y-2">
                    {!isAnyTab || isAllTab ? (
                      <VideoStatusChip status={item?.status} />
                    ) : (
                      ""
                    )}
                    <ModeratorStatusChip reviewStatus={item?.review_status} />
                  </div>
                </td>

                {!isExpiredTab && hasAccessToActions ? (
                  <td className="px-3 py-2 text-[14px] capitalize">
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-action">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="xs"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {isDeleted ? null : (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleRouteToVideo({
                                videoSlug: item?.slug,
                                status: item?.status,
                              })
                            }
                          >
                            Update Video
                          </Dropdown.Item>
                        )}

                        {isLive && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() => {
                              handleMoveVideoToExpired({ slug: item?.slug });
                            }}
                          >
                            Move to Expired
                          </Dropdown.Item>
                        )}

                        {isChangesRequired ? (
                          <>
                            {internalTeamAccess && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  dispatch(
                                    fetchRejectionReason(
                                      axiosPrivate,
                                      item.slug,
                                      setErrorMsg
                                    )
                                  );
                                  setOpenRejectReason(true);
                                }}
                              >
                                Rejection List
                              </Dropdown.Item>
                            )}
                          </>
                        ) : null}
                        {(isUnderReview || isScheduled) && (
                          <>
                            {hasAccessToMakeItLive && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  dispatch(
                                    changeSeriesStatusAsync(
                                      axiosPrivate,
                                      item.slug,
                                      "publish_now",
                                      "",
                                      () => {},
                                      false,
                                      setErrorMsg,
                                      handleGetSeries
                                    )
                                  );
                                }}
                              >
                                Make Video Live
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                        {/* {(isUnderReview || isLive) && (
                          <>
                            {internalTeamAccess && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setOpenRejectSeriesModal({
                                    open: true,
                                    slug: item?.slug,
                                    reason: "",
                                  });
                                }}
                              >
                                Reject
                              </Dropdown.Item>
                            )}
                          </>
                        )} */}
                        {(isChangesRequired || isDeleted) && (
                          <>
                            {internalTeamAccess && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  dispatch(
                                    changeSeriesStatusAsync(
                                      axiosPrivate,
                                      item.slug,
                                      "unpublish",
                                      "",
                                      () => {},
                                      false,
                                      setErrorMsg,
                                      handleGetSeries
                                    )
                                  );
                                }}
                              >
                                Move to draft
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                        {/* {isDraft || isChangesRequired ? (
                          <>
                            {(hasAccessToMakeItLive || isModerator) && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setApproveOrRejectVideoConfirmation({
                                    approve: true,
                                    reject: false,
                                    seriesSlug: item?.slug,
                                  });
                                }}
                              >
                                Approve Video
                              </Dropdown.Item>
                            )}
                          </>
                        ) : null} */}
                        {/* {isChangesRequired || isUnderReview ? (
                          <>
                            {(internalTeamAccess || isModerator) && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setApproveOrRejectVideoConfirmation({
                                    approve: false,
                                    reject: true,
                                    seriesSlug: item?.slug,
                                  });
                                }}
                              >
                                Reject Video
                              </Dropdown.Item>
                            )}
                          </>
                        ) : null} */}
                        {(isScheduled || isUnderReview) && (
                          <>
                            {hasAccessToMakeItLive && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setOpenScheduleModal({
                                    openModal: true,
                                    slug: item?.slug,
                                  });
                                  setScheduledData(item?.scheduled_info);
                                }}
                              >
                                {isUnderReview
                                  ? "Schedule Video"
                                  : "Update Schedule"}
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                        {isScheduled && (
                          <>
                            {internalTeamAccess && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() =>
                                  handleRemoveScheduled({
                                    seriesSlug: item?.slug,
                                  })
                                }
                              >
                                Remove Scheduled
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Modal to reject series */}
      <RejectSeriesModal
        openRejectSeriesModal={openRejectSeriesModal}
        setOpenRejectSeriesModal={setOpenRejectSeriesModal}
        handleRejectSeries={handleRejectSeries}
      />

      {/* Rejection reason list modal */}
      <RejectionReasonModal
        openModal={openRejectionReason}
        handleCloseModal={() => setOpenRejectReason(false)}
      />

      {/* Common component with API for Approve and Reject Video */}
      <ApproveOrRejectVideoModal
        openIsApprove={approveOrRejectVideoConfirmation?.approve}
        openIsRejected={approveOrRejectVideoConfirmation?.reject}
        seriesSlug={approveOrRejectVideoConfirmation?.seriesSlug}
        setMessage={setErrorMsg}
        handleGetSeries={handleGetSeries}
        handleClose={() =>
          setApproveOrRejectVideoConfirmation({
            approve: false,
            reject: false,
            seriesSlug: "",
          })
        }
      />

      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() =>
          setErrorMsg({ error: "", type: errorMsg?.type })
        }
      />
    </div>
  );
};

export default SeriesListTable;
