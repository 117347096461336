import React from "react";

const BankDetails = ({ isEditable, bankDetails }) => {
  return (
    <div className="mt-4">
      {bankDetails?.map((item) => {
        return (
          <div key={item?.title} className="mt-3 flex gap-x-4">
            <h6 className="w-[240px] text-[14px] lg:text-[15px] lg:w-[135px]">
              {item?.title} :
            </h6>

            {isEditable ? (
              <input
                rows={1}
                placeholder={item?.placeholder}
                className={`border-b focus:outline-none w-[55%] resize-none overflow-hidden text-[14px] lg:text-[15px] ${
                  item?.isError ? "border-red-500" : ""
                }`}
                onChange={item?.onChange}
                onInput={(e) => {
                  e.target.style.height = "auto"; // Reset height to auto
                  e.target.style.height = e.target.scrollHeight + "px"; // Set height based on scrollHeight
                }}
              />
            ) : (
              <h6 className="w-[90%]">{item?.value}</h6>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BankDetails;
