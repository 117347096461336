import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { apiGateway } from "../../utils/config";
import { errorState, GET_SHOW_ACTION_V1, SHOW_V1 } from "../../utils/constants";
import Loader from "../../components/Loader/index";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertComponent from "../Alert-Messages/alert-component.component";
import CreateShow from "../Create-Show/create-show.component";
import VideoListing from "../VideoListing";
import ShowRequiredDetails from "./ShowRequiredDetails";
import CustomModal from "../Common/CustomModal";
import useUserRoles from "../../hooks/useUserRoles";

function ShowDetails({ match }) {
  const [show, setShow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogCreate, setIsDialogCreate] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorState);
  const [dialogStatus, setDialogStatus] = useState({ open: false });
  const { isAdmin, isSuperAdmin, isEditor, internalTeamAccess } =
    useUserRoles();
  const axiosPrivate = useAxiosPrivate();
  const hasAccessToActions = internalTeamAccess;
  const showSlug = match.params.show_slug;

  const fetchShowData = (showSlug) => {
    if (!showSlug || showSlug === "" || showSlug === undefined) {
      return;
    } else {
      setIsLoading(true);
      let url = `${apiGateway}${SHOW_V1}${showSlug}/`;
      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (
            status !== 200 ||
            (data?.error_message && Object.keys(data?.error_message).length > 0)
          ) {
            setErrorMsg({
              type: "failed",
              error: data?.error_message ?? "Something went wrong",
            });
          } else {
            setShow(data?.show_data);
          }
          setIsLoading(false);
        })
        .catch(({ response }) => {
          if (response?.status === 404) {
            setErrorMsg({ type: "failed", error: "Url not found" });
          } else {
            setErrorMsg({
              type: "failed",
              error: response?.data
                ? response?.data?.error_message
                : "Something went wrong",
            });
          }
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchShowData(showSlug);
  }, []);

  const toggleStatus = (show_slug, status) => {
    let formData = new FormData();
    formData.append("show_state", status);
    let url = `${apiGateway}${GET_SHOW_ACTION_V1}/${show_slug}/update-state/`;
    // setIsLoading(true);
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status == false) {
          setErrorMsg({
            type: "failed",
            error: data?.error_message ?? "Something went wrong",
          });
        } else {
          // setIsLoading(false);
          fetchShowData(showSlug);
          setErrorMsg({ type: "success", error: "Track is status changed" });
        }
      })
      .catch(({ response }) => {
        if (response?.status == 404) {
          setErrorMsg({ type: "failed", error: "Url not found" });
        } else {
          setErrorMsg({
            type: "error",
            error: response?.data?.error_message || response?.data?.message,
          });
        }
        // setIsLoading(false);
      });
    setDialogStatus({ open: false });
  };

  const toggleCreate = (e, show_created, showSlug) => {
    if (isDialogCreate && show_created) {
      fetchShowData(showSlug);
    }
    setIsDialogCreate((prev) => !prev);
  };

  return (
    <>
      <div>
        <AlertComponent
          message={errorMsg?.error}
          type={errorMsg?.type}
          setAlertNotification={() =>
            setErrorMsg({ error: "", type: "failed" })
          }
        />
        {!isLoading ? (
          show ? (
            <div>
              <ShowRequiredDetails
                data={show}
                isAdmin={isAdmin}
                isEditor={isEditor}
                showSlug={showSlug}
                isSuperAdmin={isSuperAdmin}
                hasAccessToActions={hasAccessToActions}
                setDialogStatus={setDialogStatus}
                toggleCreate={toggleCreate}
              />

              <div className="mt-2">
                <VideoListing
                  isShowUploadCta={false}
                  isOnShowDetailsPage={{ value: true, showSlug }}
                />
              </div>
            </div>
          ) : (
            <div className="center-loader-screen">
              Sorry we failed to load the data
            </div>
          )
        ) : (
          <div className="center-loader-screen">
            <Loader />
          </div>
        )}
      </div>

      {/* Confirmation on any status changes */}
      <CustomModal
        title={show?.title}
        show={dialogStatus?.open}
        isLoading={isLoading}
        handleConfirmationBtnText="Yes"
        handleConfirmation={() => {
          toggleStatus(show?.slug, dialogStatus?.type);
        }}
        onHide={() => {
          setDialogStatus({ open: false });
        }}
      >
        <div>Are you sure you want to proceed with changes?</div>
      </CustomModal>

      {/* CREATE FORM MODAL */}
      <CustomModal
        show={isDialogCreate}
        className="lg:w-[33%]"
        title="Edit Show"
        key="edit-show"
        isButtonsRequired={false}
        onHide={toggleCreate}
      >
        <CreateShow
          toggleCreate={toggleCreate}
          show_data={show}
          setShow={setShow}
        />
      </CustomModal>
    </>
  );
}

export default withRouter(ShowDetails);
