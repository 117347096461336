import React from "react";
import { handleDefaultProfile, handleSecureInvoice } from "../../utils/utils";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPen } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import Verified from "../Common/Icons/Verified";
import useUserRoles from "../../hooks/useUserRoles";

const ProfileDetails = ({ setOpenUpdateProfile }) => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const { isCreator } = useUserRoles();
  const history = useHistory();
  const isDisableInvoiceCTA = handleSecureInvoice();
  return (
    <div>
      <div className="flex gap-x-3 mt-4 justify-between items-start">
        <div className="flex gap-x-3">
          <img
            src={
              currentUser?.avatar ||
              handleDefaultProfile({ userName: currentUser?.name })
            }
            alt={currentUser?.name || "Seekho User"}
            className="w-14 h-14 rounded-full !object-contain shadow-sm"
          />

          <div>
            <div className="flex items-center gap-x-1">
              <h6 className="text-[18px] font-medium">{currentUser?.name}</h6>
              <Verified />
            </div>
            <h6 className="!text-[14px] font-normal text-gray-500 tracking-wide mt-1">
              {currentUser?.status}
            </h6>
          </div>
        </div>

        <div className="flex gap-x-3 items-center">
          <FontAwesomeIcon
            icon={faPen}
            className="p-2 text-[32px] bg-baseBlueColor text-white rounded-full cursor-pointer"
            onClick={() => setOpenUpdateProfile(true)}
          />
          {isCreator && (
            <Tooltip
              title={
                isDisableInvoiceCTA
                  ? "You can generate an invoice only from the 4th to the 10th of every month."
                  : ""
              }
            >
              <button
                className={` flex gap-x-2 items-center justify-center px-3 py-1.5 rounded-sm font-medium text-[14px] bg-baseBlueColor text-white ${
                  isDisableInvoiceCTA ? "!cursor-not-allowed opacity-40" : ""
                }`}
                disabled={isDisableInvoiceCTA}
                onClick={() => history.push("/generate-invoice")}
              >
                <FontAwesomeIcon icon={faFile} /> INVOICE
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
