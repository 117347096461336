import React from "react";

const PerformanceMarketingDashboard = () => {
  return (
    <iframe
      title="Embedded Website"
      src={`https://analytics.seekho.in/public/dashboards/ad9nQUK51Mw5gFBGye3tiCdl8AYPyUawPpKdLaBk?org_slug=default`}
      width="100%"
      style={{
        height: "98vh",
      }}
      frameBorder="0"
    />
  );
};

export default PerformanceMarketingDashboard;
