const detailsState = {
  name: "",
  address: "",
  bankName: "",
  beneficiaryName: "",
  accountNumber: "",
  ifscCode: "",
  pan: "",
};

const missingFieldsState = {
  name: false,
  address: false,
  bankName: false,
  beneficiaryName: false,
  accountNumber: false,
  ifscCode: false,
  pan: false,
};

export { detailsState, missingFieldsState };
