import React, { useEffect, useState } from "react";
import { apiGateway } from "../../utils/config";
import { useHistory } from "react-router-dom";
import InvoiceLayout from "./InvoiceLayout";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useZustandStore from "../../zustandStore/useZustandStore";
import OverlayLoader from "../Common/OverlayLoader";

const GenerateInvoiceComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const history = useHistory();
  const setToastMessage = useZustandStore((val) => val?.setToastMessage); // global Alert state
  const axiosPrivate = useAxiosPrivate();

  const handleGetInvoiceData = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/creator/invoice/create/`;
      const response = await axiosPrivate.post(url);
      if (response?.data) {
        setIsLoading(false);
        setData(response?.data?.invoice);
      }
    } catch (error) {
      history.goBack();
      setIsLoading(false);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  useEffect(() => {
    handleGetInvoiceData();
  }, []);

  return (
    <div>
      {isLoading ? (
        <OverlayLoader className="lg:!min-w-[100%]" />
      ) : (
        <InvoiceLayout data={data} />
      )}
    </div>
  );
};

export default GenerateInvoiceComponent;
