import React from "react";
import { apiGateway } from "../../utils/config";
import { confirmationState } from "../../utils/constants/Creators";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomModal from "../Common/CustomModal";
import useZustandStore from "../../zustandStore/useZustandStore";

const ActionConfirmation = ({
  confirmationDetails,
  setConfirmationDetails,
  handleGetAllCreators,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((val) => val.setToastMessage);

  // Function to make the creator active or inactive
  const handleActiveOrInactive = async () => {
    try {
      setConfirmationDetails({ ...confirmationDetails, isLoading: true });
      const status = confirmationDetails?.type;
      const creatorId = confirmationDetails?.creatorId;
      const url = `${apiGateway}/api/v1/creator/${creatorId}/${status}/`;
      const response = await axiosPrivate.post(url);
      const { data } = response;
      if (data) {
        setToastMessage({ type: "success", message: "Updated Successfully" });
        setConfirmationDetails(confirmationState);
        handleGetAllCreators();
      }
    } catch (error) {
      setConfirmationDetails({ ...confirmationDetails, isLoading: false });
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // function deletes the creator
  const handleDeleteCreator = async () => {
    try {
      setConfirmationDetails({ ...confirmationDetails, isLoading: true });
      const creatorId = confirmationDetails?.creatorId;
      const url = `${apiGateway}/api/v1/creator/${creatorId}/delete/`;
      const response = await axiosPrivate.delete(url);
      const { data } = response;
      if (data) {
        if (data) {
          setToastMessage({ type: "success", message: "Deleted Successfully" });
          setConfirmationDetails(confirmationState);
          handleGetAllCreators();
        }
      }
    } catch (error) {
      setConfirmationDetails({ ...confirmationDetails, isLoading: false });
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // dynamic handleConfirmation base on the type of action made
  const handleConfirmation = () => {
    switch (confirmationDetails?.type) {
      case "active":
      case "inactive":
        handleActiveOrInactive();
        break;
      case "delete":
        handleDeleteCreator();
        break;
      default:
        setToastMessage({
          type: "error",
          message: "Unknown confirmation type",
        });
    }
  };
  return (
    <div>
      <CustomModal
        show={confirmationDetails?.open}
        isLoading={confirmationDetails?.isLoading}
        title="Confirmation"
        onHide={() => setConfirmationDetails(confirmationState)}
        handleConfirmation={handleConfirmation}
      >
        <p>
          Are you sure you want to {confirmationDetails?.type}{" "}
          <strong>{confirmationDetails?.creatorName || "this creator"}</strong>?
        </p>
      </CustomModal>
    </div>
  );
};

export default ActionConfirmation;
