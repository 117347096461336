import React, { useEffect, useState } from "react";
import { apiGateway } from "../../utils/config";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination, Box, Tab, Tabs } from "@mui/material";
import {
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY_LIST,
  TOGGLE_CATEGORY_STATUS,
} from "../../utils/constants";
import {
  handleAllyProps,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
} from "../../utils/utils";
import {
  categoryTabs,
  createCatDefaultState,
  handleGetMissingFields,
} from "../../utils/constants/Category";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../Common/CustomModal";
import CategoryTable from "./CategoryTable";
import AddCategory from "./AddCategory";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NoDataFound from "../Common/NoDataFound";
import CustomSearch from "../Common/CustomSearch";
import useUserRoles from "../../hooks/useUserRoles";
import useZustandStore from "../../zustandStore/useZustandStore";

const CategorySection = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isPreviousTitle, setIsPreviousTitle] = useState("");
  const [isNoData, setIsNoData] = useState(false);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [createOrEditLoading, setCreateOrEditLoading] = useState(false);
  const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState(createCatDefaultState);
  const { internalTeamAccess } = useUserRoles();
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((state) => state.setToastMessage);
  const setGlobalLoading = useZustandStore((state) => state.setGlobalLoading);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const searchParamSearchQuery = searchParams.get("query") || "";
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const hasAccessToCreate = internalTeamAccess;

  // GET List of Categories
  const handleGetCategoryList = async ({
    currentActiveTab = null,
    query,
    pageArg,
  }) => {
    try {
      let status = categoryTabs.filter((x) => x.index === currentActiveTab)?.[0]
        ?.value;
      const url = `${apiGateway}${GET_CATEGORY_LIST}?page=${
        pageArg || pageNumber
      }&q=${query}${
        status !== "all"
          ? `&is_active=${status === "active" ? "true" : "false"}`
          : ""
      }`;
      setGlobalLoading(true);
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        setGlobalLoading(false);
        setCategoryData(response?.data);
        setIsNoData(response?.data?.category_list?.length === 0 ? true : false);
      }
    } catch (error) {
      setCategoryData(null);
      setIsNoData(true);
      setGlobalLoading(false);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Triggers on tab change
  const handleChangeTabs = (event, newValue) => {
    let state = categoryTabs.filter((d) => d.index === newValue)?.[0]?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  // Triggers when searched query and hit enter key
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    }
  };

  // function with API call to create a category
  const handleCreateCategoryAPICall = async () => {
    try {
      setCreateOrEditLoading(true);
      const formData = new FormData();
      formData.append("title", categoryDetails?.title);
      formData.append(
        "meta_title",
        `${categoryDetails?.title} | ${categoryDetails?.metaTitle}`
      );
      formData.append("meta_description", categoryDetails?.metaTitle);
      formData.append("category_icon_file", categoryDetails?.image);
      formData.append(
        "website_image_file",
        categoryDetails?.websiteCategoryImage
      );
      const url = `${apiGateway}${CREATE_CATEGORY}`;
      const response = await axiosPrivate.post(url, formData);
      if (response?.data) {
        setCreateOrEditLoading(false);
        setOpenCreateCategoryModal(false);
        handleGetCategoryList({
          currentActiveTab: searchParamTabState,
          query: searchParamSearchQuery,
          pageArg: searchParamPage,
        });
        setToastMessage({
          type: "success",
          message: response?.data?.message,
        });
        setCategoryDetails({
          title: "",
          metaTitle: "",
          description: "",
          image: "",
          websiteCategoryImage: null,
          image: null,
        });
      }
    } catch (error) {
      setCreateOrEditLoading(false);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function to validates and make an api call when click on create category
  const handleCreateCategory = () => {
    const missingFields = handleGetMissingFields({ categoryDetails });
    if (missingFields.length > 0) {
      setToastMessage({
        message: `${missingFields?.map((item) => {
          return ` ${item?.value}`;
        })} fields are required`,
        type: "error",
      });
    } else {
      handleCreateCategoryAPICall();
    }
  };

  // API call when to edit category
  const handleEditCategoryAPICall = async () => {
    const missingFields = handleGetMissingFields({ categoryDetails });
    if (missingFields.length > 0) {
      setToastMessage({
        message: `${missingFields?.map((item) => {
          return ` ${item?.value}`;
        })} fields are required`,
        type: "error",
      });
    } else {
      try {
        setCreateOrEditLoading(true);
        const formatTitle = categoryDetails?.title?.split("|")[0] + " ";
        const formData = new FormData();
        // To prevent same title of the category
        if (isPreviousTitle !== categoryDetails?.title) {
          formData.append("title", categoryDetails?.title);
        }
        // this sets the meta_title, makes sure the previous is not undefined and then sets
        formData.append(
          "meta_title",
          `${formatTitle}|${
            categoryDetails?.metaTitle?.split("|")[1] !== "undefined"
              ? categoryDetails?.metaTitle?.split("|")?.pop()
              : ""
          }`
        );
        formData.append("meta_description", categoryDetails?.description);
        formData.append("category_icon_file", categoryDetails?.image);
        formData.append(
          "website_image_file",
          categoryDetails?.websiteCategoryImage
        );
        const url = `${apiGateway}${EDIT_CATEGORY}${categoryDetails?.slug}/update/`;
        const response = await axiosPrivate.post(url, formData);
        if (response?.data) {
          handleGetCategoryList({
            currentActiveTab: searchParamTabState,
            query: searchParamSearchQuery,
            pageArg: searchParamPage,
          });
          setOpenCreateCategoryModal(false);
          setCreateOrEditLoading(false);
          setIsEditCategory(false);
          setToastMessage({
            type: "success",
            message: response?.data?.message,
          });
        }
      } catch (error) {
        setCreateOrEditLoading(false);
        setToastMessage({
          type: "error",
          message: error?.response?.data?.error_message || error?.message,
        });
      }
    }
  };

  // Function prefills the state when clicked on edit in the table
  const handleEditCategory = ({ item }) => {
    setIsEditCategory(true);
    setIsPreviousTitle(item?.title);
    setOpenCreateCategoryModal(true);
    setCategoryDetails({
      title: item?.title,
      metaTitle: item?.meta_title || "",
      image: item?.home_icon,
      description: item?.meta_description || "",
      slug: item?.slug,
      websiteCategoryImage: item?.website_image,
    });
  };

  // Function to toggle the active status
  const handleToggleActive = async ({ categorySlug, status }) => {
    try {
      const url = `${apiGateway}${TOGGLE_CATEGORY_STATUS}${categorySlug}/toggle-active/`;
      const formData = new FormData();
      formData.append("is_active", status ? false : true);
      const response = await axiosPrivate.post(url, formData);
      if (response?.data) {
        setToastMessage({
          type: "success",
          error: response?.data?.message,
        });
        handleGetCategoryList({
          currentActiveTab: searchParamTabState,
          query: searchParamSearchQuery,
          pageArg: searchParamPage,
        });
      }
    } catch (error) {
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function to toggle the default status
  const handleToggleDefault = async ({ categorySlug, status }) => {
    try {
      const url = `${apiGateway}${TOGGLE_CATEGORY_STATUS}${categorySlug}/toggle-default/`;
      const formData = new FormData();
      formData.append("is_default", status ? false : true);
      const response = await axiosPrivate.post(url, formData);
      if (response?.data) {
        setToastMessage({
          type: "success",
          message: response?.data?.message,
        });
        handleGetCategoryList({
          currentActiveTab: searchParamTabState,
          query: searchParamSearchQuery,
          pageArg: searchParamPage,
        });
      }
    } catch (error) {
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // triggers when click on create category
  const handleOpenCreateCategoryModal = () => {
    setOpenCreateCategoryModal(true);
    setCategoryDetails({
      title: "",
      metaTitle: "",
      description: "",
      image: "",
      websiteCategoryImage: null,
    });
  };

  // Modal close function
  const handleCloseModal = () => {
    setOpenCreateCategoryModal(false);
    setIsEditCategory(false);
    setCategoryDetails({
      title: "",
      metaTitle: "",
      description: "",
      image: "",
      websiteCategoryImage: null,
    });
  };

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  // Effect handles API call on every change in the dependencies
  useEffect(() => {
    handleGetCategoryList({
      currentActiveTab: searchParamTabState,
      query: searchQuery || searchParamSearchQuery,
      pageArg: searchParamPage,
    });
  }, [searchParamSearchQuery, searchParamTabState, searchParamPage]);

  return (
    <div>
      {hasAccessToCreate && (
        <div
          className="cta-button-create"
          onClick={handleOpenCreateCategoryModal}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Create Category</span>
        </div>
      )}

      {/* Custom SearchBar */}
      <div className="w-[90%] lg:w-[50%]">
        <CustomSearch
          placeHolder="Search category title or id"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleInputKey={handleInputKey}
          handleClearInput={() => {
            setSearchQuery("");
            handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
          }}
        />
      </div>

      <h6 className="!text-[20px] mt-3 font-medium">Categories</h6>

      <div className="mt-3">
        <div>
          {/* Switch Tabs */}
          <div>
            <Box>
              <Tabs
                value={searchParamTabState}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
                sx={{
                  minHeight: 35,
                  height: 35,
                }}
              >
                {categoryTabs?.map((data, i) => (
                  <Tab
                    label={data?.id}
                    {...handleAllyProps({ index: i })}
                    key={i}
                    className="!text-[12px]"
                    sx={{
                      minHeight: 35,
                      height: 35,
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          </div>
          {/* Category Listing & Pagination */}
          <div className="!relative h-[calc(100vh-225px)] !overflow-x-auto rounded-md !bg-white pb-4 border-t">
            {isNoData ? (
              <div className="!flex !justify-center !items-center h-[calc(100vh-160px)]">
                <NoDataFound
                  className="!w-[350px] !mx-auto"
                  displayText="No categories to display"
                />
              </div>
            ) : (
              <div>
                <CategoryTable
                  data={categoryData?.category_list}
                  handleEditCategory={handleEditCategory}
                  handleToggleDefault={handleToggleDefault}
                  handleToggleActive={handleToggleActive}
                />

                {categoryData?.n_pages > 1 && (
                  <Pagination
                    count={categoryData?.n_pages}
                    page={searchParamPage}
                    onChange={(e, val) => {
                      setPageNumber(val);
                      handleAppendQueryToUrl({
                        history,
                        queryName: "page",
                        queryValue: val,
                      });
                    }}
                    shape="rounded"
                    className="!w-fit !mx-auto mt-4"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add Category Modal */}
      {openCreateCategoryModal && (
        <CustomModal
          show={openCreateCategoryModal}
          onHide={handleCloseModal}
          title={`${isEditCategory ? "Edit" : "Create"} Category`}
          isLoading={createOrEditLoading}
          handleConfirmationBtnText={
            isEditCategory ? "Update Category" : "Create Category"
          }
          handleConfirmation={() => {
            if (isEditCategory) {
              handleEditCategoryAPICall();
            } else {
              handleCreateCategory();
            }
          }}
        >
          <AddCategory
            categoryDetails={categoryDetails}
            setCategoryDetails={setCategoryDetails}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default CategorySection;
