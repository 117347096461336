import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { handleCopyTextToClipboard, handleFormatDate } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import {
  handleApproveOrDeclineTopic,
  handleDeleteTopic,
  handleTopicsStatusColor,
  handleTopicsTableHeadline,
} from "../../utils/constants/Topics";
import useUserRoles from "../../hooks/useUserRoles";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomReadMore from "../Common/CustomReadMore";

const TopicsListingTable = ({
  data,
  isOnTopicsPage,
  setMessage,
  handleGetTopics,
}) => {
  const [readMore, setReadMore] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { internalTeamAccess } = useUserRoles();
  const { internalTeamAccessWithViewer } = useUserRoles();
  const history = useHistory();
  const axiosPrivate = useAxiosPrivate();
  const hasAccessToActions = internalTeamAccess;

  const handleToggleReadMore = (index) => {
    setReadMore((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleApproveOrDecline = async ({ topicId, status }) => {
    const response = await handleApproveOrDeclineTopic({
      topicId,
      status,
      setMessage,
      axiosPrivate,
    });
    if (response === "success") handleGetTopics();
  };

  // Delete Topic
  const handleDelete = async ({ topicId }) => {
    const response = await handleDeleteTopic({
      topicId,
      setMessage,
      axiosPrivate,
    });
    if (response === "success") handleGetTopics();
  };

  const handleCopyReference = ({ displayText }) => {
    handleCopyTextToClipboard({ clipboardText: displayText });
    setMessage({ type: "success", error: "Reference Copied" });
  };

  const handleRowSelection = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev?.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  // Function to copy selected rows
  const handleCopySelectedRows = () => {
    const rowsToCopy = data
      ?.filter((item) => selectedRows.includes(item.id))
      ?.map((item) => {
        const formattedDate = item?.published_on
          ? handleFormatDate({
              dateInString: item?.published_on,
              showTime: false,
            })
          : "N/A";
        return `${item?.title}\t${formattedDate}\t${item?.reference}`;
      })
      .join("\n");

    navigator.clipboard.writeText(rowsToCopy);
    setMessage({
      type: "success",
      error: "Selected rows copied to clipboard!",
    });
  };

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 !bg-gray-100 !border-b">
          <tr>
            {/* Copy & Select all CTA */}
            {internalTeamAccessWithViewer && !isOnTopicsPage ? (
              <th className="px-3 py-3 whitespace-nowrap text-gray-700 !bg-gray-100">
                <div className="flex flex-col-reverse gap-y-1">
                  <input
                    type="checkbox"
                    className="border"
                    // When select all only the approved topics get selected
                    onChange={(e) =>
                      setSelectedRows(
                        e.target.checked
                          ? data
                              ?.filter((item) => item?.status === "approved")
                              .map((item) => item?.id)
                          : []
                      )
                    }
                    checked={
                      selectedRows.length > 0 &&
                      selectedRows.length ===
                        data?.filter((item) => item?.status === "approved")
                          ?.length
                    }
                  />
                  {selectedRows?.length > 0 ? (
                    <FontAwesomeIcon
                      icon={faCopy}
                      className="cursor-pointer text-gray-500"
                      onClick={handleCopySelectedRows}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </th>
            ) : (
              ""
            )}

            {handleTopicsTableHeadline({
              history,
              hasAccessToActions,
              isOnTopicsPage,
            })?.map((item) => {
              return (
                item?.display && (
                  <th
                    scope="col"
                    className={`px-3 py-3 whitespace-nowrap !text-[12px] text-gray-700 !bg-gray-100 z-[99] !font-medium `}
                    key={item?.title}
                  >
                    <div className="flex gap-x-2">
                      {item?.title}
                      {item?.sortIcon && (
                        <button onClick={item?.onClick}>
                          {item?.sortIcon}
                        </button>
                      )}
                    </div>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const isApproved = item?.status === "approved";
            const isDeclined = item?.status === "declined";
            const isDeleted = item?.status === "deleted";
            return (
              <tr
                key={item?.id}
                className="last:!border-none border-b text-gray-500 !font-normal text-[12px]"
              >
                {/* Copy select Checkbox */}
                {internalTeamAccessWithViewer && !isOnTopicsPage ? (
                  <td className="!w-[10px]">
                    {item?.status === "approved" && (
                      <div className="w-fit mx-auto">
                        <input
                          type="checkbox"
                          className="w-fit mx-auto"
                          checked={selectedRows?.includes(item?.id)}
                          onChange={() => handleRowSelection(item?.id)}
                        />
                      </div>
                    )}
                  </td>
                ) : (
                  ""
                )}

                {isOnTopicsPage && (
                  <td className="px-3 py-3 text-[14px] text-black whitespace-nowrap cursor-pointer lg:whitespace-normal tracking-wide">
                    {item?.show?.title}
                    <div className="text-[12px] text-gray-400">
                      Manager: {item?.content_manager?.name || "No manager"}
                    </div>
                  </td>
                )}

                <td className="px-3 py-3 text-[14px] text-black cursor-pointer tracking-wide lg:whitespace-normal">
                  <div className="max-w-[250px] break-words">
                    {item?.title}{" "}
                  </div>
                </td>

                <td className="px-3 py-3 text-[14px] whitespace-nowrap">
                  {handleFormatDate({
                    dateInString: item?.created_on,
                    showTime: false,
                  })}
                </td>

                <td className="px-3 py-3 text-[14px]">
                  {handleFormatDate({
                    dateInString: item?.published_on,
                    showTime: false,
                  })}
                </td>

                <td className="px-3 py-3 text-[14px] !max-w-[240px] break-words">
                  {item?.reference ? (
                    <CustomReadMore
                      index={index}
                      readMore={readMore}
                      numberOfCharactersToShow={60}
                      displayText={item?.reference}
                      handleCopyDisplayText={
                        isOnTopicsPage ? handleCopyReference : null
                      }
                      handleToggleReadMore={() => handleToggleReadMore(index)}
                    />
                  ) : (
                    "No reference"
                  )}
                </td>

                <td className="px-3 py-3 text-[12px] whitespace-nowrap">
                  <div
                    className={`w-fit px-2 py-1 rounded-xl capitalize ${handleTopicsStatusColor(
                      { status: item?.status }
                    )}`}
                  >
                    {item?.status === "waiting_for_approval"
                      ? "Waiting for approval"
                      : item?.status}
                  </div>
                </td>

                {hasAccessToActions ? (
                  <td className="px-3 py-2 text-[14px] capitalize">
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-action">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="xs"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {!isApproved && !isDeleted && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleApproveOrDecline({
                                topicId: item?.id,
                                status: "approve",
                              })
                            }
                          >
                            Approve
                          </Dropdown.Item>
                        )}

                        {!isDeclined && !isDeleted && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleApproveOrDecline({
                                topicId: item?.id,
                                status: "decline",
                              })
                            }
                          >
                            Decline
                          </Dropdown.Item>
                        )}

                        {isDeleted ? (
                          <Dropdown.Item className="action-menu-item">
                            No actions
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() => handleDelete({ topicId: item?.id })}
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TopicsListingTable;
