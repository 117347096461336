import React, { useState, useEffect } from "react";
import { Snackbar } from "@mui/material";
import { handleAlertTriggeredEvent } from "../../utils/eventFunction";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

// This component is build further using the old logic
export default function AlertComponent(props) {
  const [showAlertErrorMessage, setShowAlertErrorMessage] = useState(false);
  const [alertType, setAlertType] = useState("info"); // tracking the last valid type
  const { type, message, setAlertNotification } = props;
  const currentUser = useSelector((state) => state?.user?.currentUser);

  const closeTheAlertDeleteTheMessage = () => {
    setShowAlertErrorMessage(false);
    setTimeout(() => {
      if (props && setAlertNotification) {
        setAlertNotification("");
      }
    }, 300); // Small delay to prevent flash
  };

  useEffect(() => {
    if (message?.length > 0) {
      setShowAlertErrorMessage(true);
      setAlertType(type === "failed" ? "error" : type || "info"); // Store valid type

      // Triggering the event when only error
      if (type === "error" || type === "failed") {
        handleAlertTriggeredEvent({
          alertMessage: message,
          userData: currentUser,
        });
      }
    }
  }, [message, type]);

  return (
    <div>
      <Snackbar
        ClickAwayListenerProps={{ onClickAway: () => null }}
        open={showAlertErrorMessage}
        autoHideDuration={3000}
        onClose={closeTheAlertDeleteTheMessage}
      >
        <Alert
          variant="filled"
          onClose={closeTheAlertDeleteTheMessage}
          severity={alertType}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
