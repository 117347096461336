import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Tooltip } from "@mui/material";
import {
  detailsState,
  missingFieldsState,
} from "../../utils/constants/Invoice";
import InvoiceTable from "./InvoiceTable";
import Header from "./Header";
import BillByAndBillTo from "./BillByAndBillTo";
import BankDetails from "./BankDetails";
import useZustandStore from "../../zustandStore/useZustandStore";

const InvoiceLayout = ({ data }) => {
  const [details, setDetails] = useState(detailsState);
  const [missingFields, setMissingFields] = useState(missingFieldsState);
  const invoiceRef = useRef(null);
  const setToastMessage = useZustandStore((val) => val?.setToastMessage);

  const handlePrint = useReactToPrint({
    contentRef: invoiceRef,
    documentTitle: `${data?.app_name} Invoice ${data?.invoice_number}`,
  });

  const bankDetails = [
    {
      title: "Bank Name",
      placeholder: "Enter Bank Name",
      value: details?.bankName,
      isError: missingFields?.bankName,
      onChange: (e) => {
        setMissingFields({ ...missingFields, bankName: false });
        setDetails({ ...details, bankName: e.target.value });
      },
    },
    {
      title: "Beneficiary Name",
      placeholder: "Enter Beneficiary Name",
      value: details?.beneficiaryName,
      isError: missingFields?.beneficiaryName,
      onChange: (e) => {
        setMissingFields({ ...missingFields, beneficiaryName: false });
        setDetails({ ...details, beneficiaryName: e.target.value });
      },
    },
    {
      title: "Account Number",
      placeholder: "Enter Account Number",
      value: details?.accountNumber,
      isError: missingFields?.accountNumber,
      onChange: (e) => {
        setMissingFields({ ...missingFields, accountNumber: false });
        setDetails({ ...details, accountNumber: e.target.value });
      },
    },
    {
      title: "IFSC Code",
      placeholder: "Enter IFSC Code",
      value: details?.ifscCode,
      isError: missingFields?.ifscCode,
      onChange: (e) => {
        setMissingFields({ ...missingFields, ifscCode: false });
        setDetails({ ...details, ifscCode: e.target.value });
      },
    },
    {
      title: "PAN",
      placeholder: "Enter PAN",
      value: details?.pan,
      isError: missingFields?.pan,
      onChange: (e) => {
        setMissingFields({ ...missingFields, pan: false });
        setDetails({ ...details, pan: e.target.value });
      },
    },
  ];

  const handleMissingFields = () => {
    const updatedMissingFields = {
      ...details,
    };
    Object.keys(details).forEach((field) => {
      if (!details[field]) {
        updatedMissingFields[field] = true;
      } else {
        updatedMissingFields[field] = false;
      }
    });
    setMissingFields(updatedMissingFields);
    const hasMissingFields = Object.values(updatedMissingFields).includes(true);
    return hasMissingFields;
  };

  // Get user details and prefill the values of name and address
  useEffect(() => {
    setDetails({
      ...details,
      name: data?.creator?.name_on_agreement,
      address: data?.creator?.address,
    });
  }, [data?.creator]);

  return (
    <div>
      <div className="lg:!w-[85%] mx-auto bg-white px-2 pb-6">
        {/* Invoice layout, The UI with Input */}
        <div className="w-[90%] mx-auto bg-white py-4">
          <Header data={data} />

          {/* Bill to and Bill By */}
          <div>
            <BillByAndBillTo
              isEditable
              data={data}
              details={details}
              missingFields={missingFields}
              setDetails={setDetails}
              setMissingFields={setMissingFields}
            />
          </div>

          {/* Table */}
          <div className="mt-8">
            <InvoiceTable data={data} />
          </div>

          {/* Bank Details */}
          <BankDetails
            isEditable
            bankDetails={bankDetails}
            details={details}
            setDetails={setDetails}
          />
        </div>

        {/* Generate CTA */}
        <div className="! mt-3 flex justify-between">
          <button
            className="px-3 py-2 rounded-lg text-white bg-baseBlueColor ml-0 font-semibold text-[14px]"
            onClick={() => {
              if (handleMissingFields()) {
                setToastMessage({
                  type: "error",
                  message: "Please fill all the fields",
                });
              } else {
                handlePrint();
              }
            }}
          >
            GENERATE INVOICE
          </button>
          <a href="mailto:payment@seekhoapp.com">
            <Tooltip title={"Share the downloaded invoice via mail"}>
              <button className="px-3 py-2 rounded-lg text-white bg-baseBlueColor ml-0 font-semibold text-[14px]">
                SEND VIA MAIL
              </button>
            </Tooltip>
          </a>
        </div>
      </div>

      {/* Actual Invoice UI with no input, This will be the Final Downloadable Invoice */}
      <div className="hidden">
        <div className=" w-[90%] mx-auto bg-white" ref={invoiceRef}>
          <div className="!pt-20">
            <Header data={data} />
          </div>

          {/* Billed by & Billed To details */}
          <BillByAndBillTo
            data={data}
            details={details}
            setDetails={setDetails}
          />

          {/* Table */}
          <div className="mt-8">
            <InvoiceTable data={data} />
          </div>

          {/* Bank Details */}
          <BankDetails
            isEditable={false}
            details={details}
            bankDetails={bankDetails}
            setDetails={setDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceLayout;
