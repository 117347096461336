import React from "react";

const BillByAndBillTo = ({
  isEditable,
  details,
  data,
  missingFields,
  setDetails,
  setMissingFields,
}) => {
  return (
    <div>
      {/* Billed by & Billed To details */}
      <div
        className={` mt-10 flex w-full gap-x-16 ${
          isEditable ? "flex-col gap-y-8 lg:flex-row" : ""
        }`}
      >
        {/* Billed By */}
        <div className={`${isEditable ? "w-full" : "w-[50%]"}`}>
          <h1 className="font-bold border-b-2 border-gray-200 pb-1">
            Bill To:
          </h1>

          <div className="mt-3">
            <b>{data?.billed_to?.name}</b> <br />
            {data?.billed_to?.address} <br />
            GSTIN : <b>{data?.billed_to?.gstin}</b> <br />
            Email Id : {data?.billed_to?.email_id}
          </div>
        </div>

        <div className={`${isEditable ? "w-full" : "w-[50%]"}`}>
          <h1 className="font-bold border-b-2 border-gray-200 pb-1">
            Billed By:
          </h1>
          <div className="mt-2">
            <div className="mt-3 flex gap-x-4">
              <h6 className="w-[80px]">Name:</h6>
              {isEditable ? (
                <input
                  rows={1}
                  placeholder="Enter Name"
                  value={details?.name}
                  className={` border-b focus:outline-none w-full resize-none overflow-hidden ${
                    missingFields?.name ? "border-red-500" : ""
                  }`}
                  onChange={(e) => {
                    setMissingFields({ ...missingFields, name: false });
                    setDetails({ ...details, name: e.target.value });
                  }}
                  onInput={(e) => {
                    e.target.style.height = "auto"; // Reset height to auto
                    e.target.style.height = e.target.scrollHeight + "px"; // Set height based on scrollHeight
                  }}
                />
              ) : (
                <div>{details?.name}</div>
              )}
            </div>

            <div className="mt-3 flex gap-x-4">
              <h6 className="w-[80px]">Address:</h6>
              {isEditable ? (
                <textarea
                  id="ThinGrayScrollBar"
                  placeholder="Enter Address"
                  value={details?.address}
                  className={`border-b focus:outline-none w-full resize-none overflow-auto ${
                    data?.creator?.address ? "!min-h-[100px] max-h-[250px]" : ""
                  } ${missingFields?.address ? "border-red-500" : ""}`}
                  onChange={(e) => {
                    setMissingFields({ ...missingFields, address: false });
                    setDetails({ ...details, address: e.target.value });
                  }}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                />
              ) : (
                <h6
                  className="break-words"
                  dangerouslySetInnerHTML={{
                    __html: details?.address?.replace(/\n/g, "<br/>"),
                  }}
                ></h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillByAndBillTo;
